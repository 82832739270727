<template>
  <div>
    <!-- <b-row class="match-height">
        <b-col lg="6">
          <order-add-new />
        </b-col>
        <b-col lg="6">
          <order-pie-chart />
        </b-col>
      </b-row> -->
    <b-row>
      <b-col>
        <invoice-table :generalInvoiceTable="true" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue';
import InvoiceTable from '@/layouts/components/tables/InvoiceTable.vue';

export default {
  components: {
    BRow,
    BCol,
    InvoiceTable,
  },
};
</script>
